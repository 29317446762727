<template>
  
        
        <div>           
            <kendo-grid ref="grid"
                        :data-source="dataSource"
                        :pageable="pageableConfig"
                        :filterable="filterableConfig"
                        :sortable="true"
                        :columns=columns
                        v-on:detailinit="detailInit"
                        :resizable="true"
                        >
            </kendo-grid>
            <production-report-pdf ref="ProductionReportPdf" />
        </div>
        
    
</template>


<script>
import { Grid, GridColumn } from '@progress/kendo-grid-vue-wrapper';
import { apiBaseUrl } from '../../../../infrastructure/constant/connection'
import productionReportService from '../Script/ProductionReportService.js';
import productionReportPDF from '../../../../assets/template/ProductionReportPdfTemplate.vue';
import moment from 'moment';
import { response } from '../../../../infrastructure/constant/response';
import { kendo_grid, sweet_alert, datetime_zone } from '../../../../infrastructure/constant/variable';

export default {
    name: 'ProductionReportGrid',
    components: {
        'kendo-grid' : Grid,
        'kendo-grid-column': GridColumn,
        'production-report-pdf': productionReportPDF
    },
    props: ['dateFrom', 'dateTo'],
    data(){
        var dateFrom = this.$props.dateFrom;
        var dateTo = this.$props.dateTo;
        return{
            DateFrom : dateFrom,
            DateTo: dateTo,
            dataSource: new kendo.data.DataSource({
                page: 1,
                pageSize: kendo_grid.default_grid_pagesize,
                transport: {
                    read: {
                        contentType: "application/json",
                        url: apiBaseUrl,
                        type: "POST",
                        data: function(e) {
                            const variables = {
                                startDate: dateFrom + datetime_zone.date_from,
                                endDate: dateTo + datetime_zone.date_to
                            }
                            return { 
                                query: productionReportService.readQuery(),
                                variables: variables
                            };
                        },
                        beforeSend: function (req) {
                            req.setRequestHeader('Authorization', window.$cookies.get('Token'));
                            req.setRequestHeader('Path', window.location.pathname);
                        }
                    },
                    parameterMap: function(options, operation) {
                        return  kendo.stringify({
                            query: options.query,
                            variables: options.variables
                        });
                    }
                },
                schema: {
                    data: function(response) {
                        if (response.data.GetProductionReport == null){
                            return [];
                        }
                        else
                            return response.data.GetProductionReport;
                    },
                    total: function(response) {
                        if (response.data.GetProductionReport == null){
                            return 0;
                        }
                        else
                            return response.data.GetProductionReport.length;
                    },
                    model: {
                        fields: {
                            production_reject_quantity: {type: "number" },
                            production_result_quantity: {type: "number" },
                            production_cost: {type: "number" },
                            production_start_date: {type: "date"},
                            production_finish_date: {type: "date"},
                            production_start_date_est: {type: "date"},
                            production_finish_date_est: {type: "date"},
                        }
                    }
                },
            }),
            columns:  [
                { field: "production_code", title: "Kode Produksi", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "status", title: "Status", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "production_reject_quantity", title: "Total Pembuangan", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-right" } }, 
                { field: "production_result_quantity", title: "Total Produksi", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-right" } },
                { field: "production_cost", title: "Biaya Produksi", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-right" } },
                { field: "production_start_date", title: "Tanggal Mulai", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes:{style:"text-align:center"},
                    template: "#= production_start_date == null ? '' : kendo.toString(kendo.parseDate(production_start_date, 'yyyy-MM-dd'), 'dd-MM-yyyy') #",
                    filterable : {
                        ui: function (element) {
                            element.kendoDatePicker({
                                format: "yyyy-MM-dd"
                            });
                        }
                    }
                },
                { field: "production_finish_date", title: "Tanggal Selesai", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes:{style:"text-align:center"},
                    template: "#= production_finish_date == null ? '' : kendo.toString(kendo.parseDate(production_finish_date, 'yyyy-MM-dd'), 'dd-MM-yyyy') #",
                    filterable : {
                        ui: function (element) {
                            element.kendoDatePicker({
                                format: "yyyy-MM-dd"
                            });
                        }
                    }
                },
                { field: "production_start_date_est", title: "Estimasi Tanggal Mulai", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes:{style:"text-align:center"},
                    template: "#= production_start_date_est == null ? '' : kendo.toString(kendo.parseDate(production_start_date_est, 'yyyy-MM-dd'), 'dd-MM-yyyy') #",
                    filterable : {
                        ui: function (element) {
                            element.kendoDatePicker({
                                format: "yyyy-MM-dd"
                            });
                        }
                    }
                },
                { field: "production_finish_date_est", title: "Estimasi Tanggal Selesai", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes:{style:"text-align:center"},
                    template: "#= production_finish_date_est == null ? '' : kendo.toString(kendo.parseDate(production_finish_date_est, 'yyyy-MM-dd'), 'dd-MM-yyyy') #",
                    filterable : {
                        ui: function (element) {
                            element.kendoDatePicker({
                                format: "yyyy-MM-dd"
                            });
                        }
                    }
                },
            ],
            filterableConfig:  {
                extra: true,
                operators: kendo_grid.client_operator
            },
            pageableConfig: kendo_grid.default_pageable_config,
            sortableConfig: {
                mode: "multiple"
            },
        }
    },
    
    methods: {
        async exportExcel () {
            var variables = {
                startDate : this.DateFrom,
                endDate : this.DateTo,
            };
            var gridData = this.$refs.grid.kendoWidget().dataSource._data;
            var data = productionReportService.productionReportExcel(gridData, variables);

            if(gridData.length == 0) {
                this.$swal("Error", response.noRecord, "error");
            }
            else {
                var XLSX = require("xlsx");
                var dateNow = moment(new Date()).format("YYYY-MM-DD-HHmmss");

                var filename = 'ProductionReport_' + dateNow + '.xlsx';
                var ws = XLSX.utils.json_to_sheet(data, {skipHeader: true});
                ws['!cols'] = productionReportService.productionReportExcelHeaderSize();
                
                var wb = XLSX.utils.book_new();

                XLSX.utils.book_append_sheet(wb, ws);
                XLSX.writeFile(wb, filename);
            }
            
        },
        generatePDF(){
            var gridData = this.$refs.grid.kendoWidget().dataSource._data;
            this.$refs.ProductionReportPdf.generatePDF(gridData);
        },
        detailInit: function (e) {
            $('<div/>').appendTo(e.detailCell).kendoGrid({
                ref: "ProductionReportDetailGrid",
                dataSource: {
                    transport: {
                        read: function (x) {
                            x.success(e.data.production_material);
                        },
                    },
                    parameterMap: function(options, operation) {
                        return kendo.stringify({
                            query: options.query,
                            variables: options.variables
                        });
                    },
                    schema: {
                        data: function (response) {
                            if(response == null)
                                return []
                            else
                                return response
                        },
                        total: function(response) {
                            if(response == null)
                                return 0
                            else
                                return response.length;
                        }
                    },
                    pageSize: kendo_grid.default_detail_grid_pagesize
                },
                scrollable: false,
                sortable: true,
                pageable: true,
                navigatable: true,
                resizable: true,
                columns: [
                    { field: 'actual_quantity', title: "Kuantitas Asli", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes:{ style: "text-align:right;"}, editable: false, nullable: true },
                    { field: 'waste_quantity', title: "Kuantitas Pembuangan", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes:{ style: "text-align:right;"}, editable: false, nullable: true },
                ],
            })
        },
    }
}
</script>

<style scoped>
</style>
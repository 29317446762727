<template>
    <div>
        <CCard class="w-100">
            <CCardHeader>
                <h2>Report Production</h2>
            </CCardHeader>
            <CCardBody>
                <CRow>
                    <CCol class="sm-3">
                        <CRow>
                            <CCol class="sm-2" >
                                <label class="form-label font-weight-bold">Tipe Tanggal</label>
                                <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                            </CCol>
                            <CCol class="sm-3" >
                                <CSelect id="DateType" class="font-weight-bold" @change="onChangeDateType()" :options="DateTypeData" :value.sync="DateType" />
                                <label id="errorDateType" class="form-error pb-2" style="display: none; color: red;"></label>
                            </CCol>
                        </CRow>
                    </CCol>

                    <CCol class="sm-3">
                        <CRow>
                            <CCol class="sm-2">
                                <label class="form-label font-weight-bold">Tanggal Mulai</label>
                                <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                            </CCol>
                            <CCol class="sm-3">
                                <date-picker
                                    v-model="DateFrom"
                                    format="DD MMM YYYY"
                                    type="date"
                                    placeholder="Pilih tanggal"
                                    value-type="YYYY-MM-DD"
                                    style="width:100%"
                                    @change = "onChangeDateFrom"
                                >
                                </date-picker>
                                <label id="errorDateFrom" class="form-error pb-2" style="display: none; color: red;"></label>
                            </CCol>
                        </CRow>
                    </CCol>

                    <CCol class="sm-3">
                        <CRow>
                            <CCol class="sm-2">
                                <label class="form-label font-weight-bold">Tanggal Akhir</label>
                                <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                            </CCol>
                            <CCol class="sm-3">
                                <date-picker
                                    v-model="DateTo"
                                    format="DD MMM YYYY"
                                    type="date"
                                    placeholder="Pilih tanggal"
                                    value-type="YYYY-MM-DD"
                                    style="width:100%"
                                    @change = "onChangeDateTo"
                                ></date-picker>
                                <label id="errorDateTo" class="form-error pb-2" style="display: none; color: red;"></label>
                            </CCol>
                        </CRow>
                    </CCol>
                </CRow>

                <hr>
                <CRow >
                    <CCol class="sm-3">
                        <button id="view" class="btn btn-primary" style="float:left"
                            @click="exportExcel()">
                            <i class="fa fa-file-excel-o"></i> Excel
                        </button>
                        &nbsp;
                        <button id="view" class="btn btn-secondary"
                            @click="generatePDF()">
                            <i class="fa fa-file-pdf-o"></i> PDF
                        </button>
                    </CCol>
                </CRow>

                <hr>               
                <CRow>  
                    <CCol>
                        <production-report-grid 
                            ref="productionReportGrid"
                            :key="gridReload" 
                            :dateFrom="DateFromChangest" 
                            :dateTo="DateToChangest" 
                         />
                    </CCol>
                </CRow>
            </CCardBody>
        </CCard>
    </div>
</template>

<script>
import '@progress/kendo-ui';
import '@progress/kendo-theme-default/dist/all.css';
import 'vue2-datepicker/index.css'
import datePicker from 'vue2-datepicker';
import moment from 'moment';
import { report, sweet_alert } from '../../../infrastructure/constant/variable';
import productionReportGrid from '../ProductionReport/Grid/ProductionReportGrid.vue';

export default {
    name: 'ProductionReport',
    components: {
        'production-report-grid': productionReportGrid,
        'date-picker': datePicker,
    },
    async mounted() {
        this.DateTypeData = this.$globalfunc.getDateTypeData();

        this.onChangeDateType();
    },
    data(){
        return{
            DateTypeData: [],
            DateType: report.default_date,

            DateFrom: '',
            DateTo: '',

            DateFromChangest: report.default_start_date,
            DateToChangest: report.default_end_date,

            gridReload: 0,
        }
    },
    methods: {
        reload(){
            this.gridReload++;
        },
        async onChangeDateType(){
            var dateTypeData = this.$globalfunc.getDateTypeDetail(this.DateType);
            this.DateFrom = dateTypeData.startDate == null ? this.DateFrom : dateTypeData.startDate;
            this.DateTo = dateTypeData.endDate == null ? this.DateTo : dateTypeData.endDate;
            this.DateFromChangest = this.DateFrom;
            this.DateToChangest = this.DateTo;
            this.gridReload++;
        },
        async onChangeDateFrom(){
            var vue = this;
            if((moment(this.DateFrom).valueOf()-moment(this.DateTo).valueOf()) > 0){
                this.DateFrom = this.DateFromChangest;
                vue.$swal("Error", "Tanggal awal tidak boleh lebih besar dari tanggal akhir", "error");
            }else{
                this.DateFromChangest = this.DateFrom;
                this.DateToChangest = this.DateTo;
                this.gridReload++;
                this.DateType = 'Custom';
            }
        },
        async onChangeDateTo(){
            var vue = this;
            if((moment(this.DateFrom).valueOf()-moment(this.DateTo).valueOf()) > 0){
                this.DateTo = this.DateToChangest;
                vue.$swal("Error", "Tanggal awal tidak boleh lebih besar dari tanggal akhir", "error");
            }else{
                this.DateFromChangest = this.DateFrom;
                this.DateToChangest = this.DateTo;
                this.gridReload++;
                this.DateType = 'Custom';
            }
        },
        async exportExcel() {
            this.$refs.productionReportGrid.exportExcel();
        },
        generatePDF() {            
            // this.$swal(sweet_alert.print).then((result) => {
            //     if (result.isConfirmed == true) {
            //         this.$refs.productionReportGrid.generatePDF();
            //     }
            //     else if (result.isDenied) {
            var startDate = this.DateFrom;
            var endDate = this.DateTo;

            const routeData = this.$router.resolve({name: 'Production Report Html Template', params: { startdate:startDate, enddate:endDate }});
            window.open(routeData.href, '_blank');
            //     }
            // });
        },
    }
}
</script>

<style scoped>
</style>